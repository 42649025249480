<template>
  <div>
    <div class="text-center">
      <span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--success mt-3">
        <label>
          <input
            @click="mrReadyStatus()"
            type="checkbox"
            name="mr_ready"
            class="mr_ready"
            :checked="mrReady"
            v-model="mrReady"
          />
          <span></span>
        </label>
      </span>
    </div>
  </div>
</template>

<script>
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");

export default {
  data: function() {
    return {
      mrReady: false
    };
  },
  props: {
    reservationVue: { type: Object },
    rowData: { type: Object },
    modalName: { type: String }
  },
  methods: {
    mrReadyStatus() {
      blockUI.blockPage();
      if (this.mrReady) {
        this.update(false);
      } else {
        this.update(true);
      }
    },
    async update(status) {
      var vx = this;

      var params = {
        id: this.rowData.id,
        isMRReady: status
      };

      var message;

      if (status) {
        message = "Medical Record telah disiapkan.";
      } else {
        message = "Medical Record tidak jadi disiapkan.";
      }
      this.reservationVue.isAuthRefreshState = false;
      await ReservationRepository.updateReservation(params)
        .then(response => {
          this.reservationVue.isAuthRefreshState = true;
          blockUI.unblockPage();
          vx.mrReady = status;
          vx.reservationVue.showToast(message);
        })
        .catch(error => {
          this.reservationVue.isAuthRefreshState = true;
          vx.mrReady = !status;
          blockUI.unblockPage();
        });
    }
  },
  mounted() {
    this.mrReady = this.rowData.isMRReady;
  }
};
</script>
