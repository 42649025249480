<template>
  <div>
    <base-header base-title="Rekam Medik"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                ref="auth"
                v-if="isAuthRefreshState"
                :table-name="tableName"
                :on-refresh="fetch"
                :auto-refresh-state="isAuthRefreshState"
              ></auto-refresh>&nbsp;&nbsp;
              <sound-notifiction ref="notif"></sound-notifiction>&nbsp;&nbsp;
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-inline">
                <div class="form-group mb-2">
                  <div class="input-group input-group-sm date">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la la-calendar-o glyphicon-th"></i>
                      </span>
                    </div>
                    <date-range-picker
                      :changedValueStart.sync="dateStart"
                      :changedValueEnd.sync="dateEnd"
                      id-date-picker="filter_rangedatepicker"
                      picker-value
                      place-holder="DD/MM/YYYY - DD/MM/YYYY"
                    ></date-range-picker>
                  </div>
                </div>
                <button
                  type="button"
                  @click="submitSearch()"
                  class="btn btn-sm btn-default btn-bold btn-upper mb-2 ml-2"
                >
                  <i class="flaticon2-search"></i> {{ $t("button.search") }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="showLoading"
            class="m-auto p-5 d-flex flex-row h-100"
          >
            <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
            <h5 class="my-auto pl-5">Loading ...</h5>
          </div>
          <div v-if="isLoaded">
            <data-tables :table="table"></data-tables>
          </div>
        </div>
        <form-modal
          v-if="isShowModal"
          :show-modal.sync="isShowModal"
          @event="fetch"
          :modal-name="modalName"
          :table-name="tableName"
          :modal-data="dataEdit"
        ></form-modal>
        <form-modal-location
          v-if="isShowModalLocation"
          :show-modal.sync="isShowModalLocation"
          @event="fetch"
          :modal-name="modalLocationName"
          :table-name="tableName"
          :modal-data="dataEditLocation"
        ></form-modal-location>
        <modal-detail
          v-if="isShowModalDetail"
          :show-modal.sync="isShowModalDetail"
          :modal-name="modalDetailName"
          :table-name="tableName"
          :modal-data="dataDetail"
        ></modal-detail>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import AutoRefresh from "./../_general/AutoRefresh";
import BaseHeader from "./../_base/BaseHeader";
import BaseTitle from "./../_base/BaseTitle";
import DateRangePicker from "./../_general/DateRangePicker";
import DataTables from "./../_table/DataTables";
import BtnReady from "./_components/BtnReady";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
import DateRange from './../../constants/date-range-enum';
import SoundNotifiction from './../_general/SoundNotification';
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
  components: {
    AutoRefresh,
    BaseHeader,
    DataTables,
    BaseTitle,
    DateRangePicker,
    ModalDetail: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-detail-mr" */ "./_components/ModalDetail.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormModal: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-medical-record" */ "./_components/FormModal.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormModalLocation: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-location-medical-record" */ "./_components/FormModalLocation.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    SoundNotifiction
  },
  data() {
    var vx = this;
    return {
      isLoaded: false,
      showLoading: true,
      isShowModal: false,
      isShowModalLocation: false,
      isShowModalDetail: false,
      dataEdit: null,
      dataDetail: null,
      dataEditLocation: null,
      dateStart: null,
      dateEnd: null,
      headTitle: "Daftar Reservasi",
      tableName: "table_medical_record",
      modalName: "modal_medical_record",
      modalLocationName: "modal_mr_location",
      modalDetailName: "modal_medical_record",
      modalIcon: "flaticon2-add-1",
      table: {
        tableName: "table_medical_record",
        tableDataResult: [],
        tableColumns: [
          {
            title: "ID",
            data: "lastUpdateStatus"
          },
          {
            title: "Tanggal",
            data: "createdDate"
          },
          {
            title: "Nama",
            data: "nama"
          },
          {
            title: "Dokter",
            data: function(row, type) {
                            if(row.jadwal) {
                                if(row.jadwal.dokter){
                                    return row.jadwal.dokter.nama
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Jadwal Kunjungan",
            data: "tanggalKunjungan"
          },
          {
            title: "Estimasi",
            data: "estimasiWaktu"
          },
          {
            title: "No Rekam Medik",
            data: function(row, type) {
                            if(row.patient) {
                                if(row.patient.medicalNo){
                                    return row.patient.medicalNo
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Ready",
            data: "isMRReady",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnReady = Vue.extend(BtnReady);
              let instance = new btnReady({
                propsData: {
                  reservationVue: vx,
                  rowData: rowData,
                  modalName: vx.modalName
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  reservationVue: vx,
                  rowData: rowData,
                  modalName: vx.modalName
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          },
        ],
        tableColumnDefs: [
          {
            defaultContent: "-",
            targets: "_all",
          },
          {
            targets: [0],
            visible: false,
            render: function (t, e, a, n) {
              var momentDate = moment(t).unix();
              return momentDate;
            }
          },
          {
            targets: [1, 4],
            width: "15%",
            render: function (t, e, a, n) {
              var momentDate = moment(t);
              return momentDate.format("DD/MM/YYYY");
            }
          },
          {
            targets: 5,
            render: function (t, e, a, n) {
              if (t) {
                return t.substr(0, 5);
              } else {
                return "";
              }
            }
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableFilter: [
          {
            title: "Nama",
            type: "text"
          },
          {
            title: "No Rekam Medik",
            type: "text"
          },
          {
            title: "Estimasi",
            type: "text"
          },
          {
            title: "Dokter",
            type: "combobox"
          },
          {
            title: "Tanggal",
            type: "datepicker"
          },
          {
            title: "Jadwal Kunjungan",
            type: "datepicker"
          },
          {
            title: "Ready",
            type: "ready"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ],
        createdRow:( row, data, dataIndex ) => {
            if(data.isNewBO){
                $( row ).addClass('highlight');
            }
        }
      },
      maxNotifDate: moment(DateRange.MIN_DATE),
      isAuthRefreshState: false,
    };
  },
  watch: {
    isShowModal: function (val) {
      if (!val) {
        this.dataEdit = null;
      }
      this.isAuthRefreshState = (val) ? false : true;
    },
    isShowModalDetail: function (val) {
      if (!val) {
        this.dataDetail = null;
      }
    },
    isShowModalLocation: function (val) {
      if (!val) {
        this.dataEditLocation = null;
      }
       this.isAuthRefreshState = (val) ? false : true;
    }
  },
  methods: {
    showModal: function () {
      this.isShowModal = true;
    },
    showModalDetail: function () {
      this.isShowModalDetail = true;
    },
    showModalLocation: function () {
      this.isShowModalLocation = true;
    },
    submitSearch: function () {
      this.showLoading = true;
      this.isLoaded = false;
      this.fetch(undefined, true);
    },
    async fetch(resolve, isFirstReload = false) {
      var vx = this;
      if (!this.isLoaded) {
        this.showLoading = true;
      }
      var listStatus = this.getListParam();
      var params = {
        "tanggalKunjungan.greaterOrEqualThan": this.dateStart,
        "tanggalKunjungan.lessOrEqualThan": this.dateEnd
      }

      if(listStatus.length > 0){
        params["status.in"] = listStatus.join(', ')
      }
      this.isAuthRefreshState = false;
      new Promise((resolve, reject) => {
        ReservationRepository.get(params, resolve, reject);
      })
        .then(response => {
          let call = false;
          const data = (!isFirstReload) ? response.data.map(x => {
              x.isNewBO = false;
              if(listStatus.includes(x.status)){
                x.isNewBO = moment.utc(x.lastUpdateStatus).isAfter(vx.maxNotifDate);
                call = (x.isNewBO) ? true : call;
              }
              return x;
          }) : response.data;
          this.maxNotifDate = (data.length > 0) ? moment.max(data.map(x => moment.utc(x.lastUpdateStatus))) : (isFirstReload) ? moment(DateRange.MIN_DATE) : this.maxNotifDate;
          if(call){
              this.$refs.notif.ring();
          }
          this.isAuthRefreshState = true;
          if (this.isLoaded) {
            var datatable = $("#" + this.tableName).DataTable();
            datatable.clear();
            datatable.rows.add(data);
            datatable.draw("full-hold");
            if (resolve !== undefined) {
              resolve(data);
            }
            this.showLoading = false;
          } else {
            this.table.tableDataResult = data;

            this.showLoading = false;
            this.isLoaded = true;
          }
        }).catch(error => {
          this.isAuthRefreshState = true;
          var msg = (error.message) ? error.message : error;
          this.showToastError('Reservation : ' + msg);
        })
    },
    showToast: function (message) {
      toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: true,
        progressBar: false,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "5000",
        extendedTimeOut: "1000",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
      };

      toastr.success(message);
    },
    showToastError: function (message) {
      toastr.error(message);
    },
    getListParam: function(){
        let data = [];
        if(checkPermission.isCanAccess('BackOffice.Reservasi.Status.NEW')){
            data.push('NEW');
        }
        if(checkPermission.isCanAccess('BackOffice.Reservasi.Status.CONFIRM')){
            data.push('CONFIRM');
        }
        if(checkPermission.isCanAccess('BackOffice.Reservasi.Status.ARRIVED')){
            data.push('ARRIVED');
        }
        return data;

    }
  },
  mounted() {
    this.fetch(undefined, true);
  }
};
</script>
