<template>
  <div class="text-center">
    <template>
      <button
        @click="detailReservation()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Detail"
      >
        <i class="la la-eye"></i>
      </button>
    </template>
    <template v-if="checkPermission('PatientResource.PATCH.Patient')">
      <button
        @click="editMedicalRecord()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission('MRLocationResource.POST.MRLocation')">
      <button
        @click="editLocation()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Update Lokasi"
        :disabled="!rowData.nomorMedicalRecord"
        :class="[!rowData.nomorMedicalRecord ? 'disable-icon' : '']"
      >
        <i class="la la-map-marker"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import SweetAlertTemplate from "./../../_general/SweetAlert";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
const MRTrackingRepository = RepositoryFactory.get("mrtracking");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  props: {
    reservationVue: { type: Object },
    rowData: { type: Object },
    modalName: { type: String }
  },
  methods: {
    editLocation() {
      this.getLocationHistory();
    },
    async getLocationHistory() {
      this.reservationVue.dataEditLocation = this.rowData;
      this.reservationVue.showModalLocation();
    },
    editMedicalRecord() {
      this.reservationVue.dataEdit = this.rowData;
      this.reservationVue.showModal();
    },
    detailReservation() {
      this.reservationVue.dataDetail = this.rowData;
      this.reservationVue.showModalDetail();
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  }
};
</script>
